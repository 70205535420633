import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./style";

type IButton = {
  text: string;
  icon?: any;
  to: string | any;
  spacing?: number;
  className?: string;
  onClick?: any;
  disabled?: boolean;
  children?: React.ReactNode;
};

const ButtonLink: React.FC<IButton> = (props: IButton) => {
  const {
    text,
    icon,
    spacing = 10,
    to,
    className = "",
    onClick,
    disabled,
  } = props;
  const classes = useStyles({
    spacing,
  });

  const cssClass = className || classes.button;

  return (
    <Link
      className={cssClass}
      style={disabled ? { pointerEvents: "none", opacity: 0.8 } : {}}
      {...(onClick && { onClick })}
      to={to}
    >
      <p className={classes.buttonContent}>
        {icon && <img src={`/images/${icon}`} alt="" />}
        <span className={classes.buttonText}>{text}</span>
      </p>
    </Link>
  );
};

export default ButtonLink;
